import { captureSnapshot, TCapturedSnapshot } from 'common/utils/features/capture-snapshot';
import { renderSnapshot } from 'common/utils/features/render-snapshot';
import { IVideoStream } from 'common/utils/features/video-stream';
import { cx } from 'emotion';
import { forwardRef, memo, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { ShutterEffect, TShutterEffectRef } from './../shutter-effect';
import * as styles from './video-box.styles';

export type TVideoBoxRef = {
  init(stream: IVideoStream): Promise<void>;
  capture(): Promise<TCapturedSnapshot>;
  reset(): void;
};

const waitMetadataMs = 1000;
const maxScale = 8;
const wheelStep = 1;
const isTransformable = true;

export const VideoBox = memo(
  forwardRef((_: object, ref: Ref<TVideoBoxRef>) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const shutterEffectRef = useRef<TShutterEffectRef>(null);
    const transformRef = useRef<ReactZoomPanPinchRef>(null);
    const unmountCallbackRef = useRef<VoidFunction | null>(null);

    const [isCaptured, setCaptured] = useState(false);

    useImperativeHandle(
      ref,
      () => ({
        async init(stream: IVideoStream): Promise<void> {
          const video = videoRef.current;

          if (!video) {
            throw new Error('video element is not found');
          }

          video.srcObject = stream;

          // Далее ждем либо onloadedmetadata, либо 1 секунду - что произойдет быстрее
          await Promise.race([
            new Promise<void>((resolve: VoidFunction) => {
              window.setTimeout(resolve, waitMetadataMs);
            }),
            new Promise<void>((resolve: VoidFunction) => {
              video.onloadedmetadata = resolve;
            })
          ]);

          unmountCallbackRef.current = () => stream.destroy();

          await video.play();
        },
        async capture(): Promise<TCapturedSnapshot> {
          const video = videoRef.current;
          const canvas = canvasRef.current;

          if (!video) {
            throw new Error('video element is not found');
          }

          if (!canvas) {
            throw new Error('canvas element is not found');
          }

          shutterEffectRef.current?.run();
          transformRef.current?.resetTransform();

          renderSnapshot(video, canvas);
          setCaptured(true);

          return captureSnapshot(canvas);
        },
        reset(): void {
          transformRef.current?.resetTransform();
          setCaptured(false);
        }
      }),
      []
    );

    useEffect(() => {
      return () => {
        const unmountCallback = unmountCallbackRef.current;

        unmountCallback && unmountCallback();
      };
    }, []);

    return (
      <div className={styles.root}>
        <video className={styles.video} ref={videoRef} muted playsInline disablePictureInPicture autoPlay />
        <div className={cx(styles.canvas, isCaptured && styles.captured)}>
          {isTransformable ? (
            <TransformWrapper
              ref={transformRef}
              wheel={{ step: wheelStep }}
              maxScale={maxScale}
              doubleClick={{ disabled: false, mode: 'reset' }}
              limitToBounds
              centerZoomedOut
            >
              <TransformComponent>
                <canvas ref={canvasRef} />
              </TransformComponent>
            </TransformWrapper>
          ) : (
            <canvas ref={canvasRef} />
          )}
        </div>
        <ShutterEffect ref={shutterEffectRef} className={styles.shutterEffect} />
      </div>
    );
  })
);
