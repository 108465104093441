import { Avatar } from '@t15-ui-kit/avatar';
import { ColorsBasicEnum, ColorsShadesEnum } from '@t15-ui-kit/enums';
import { useMobile } from '@t15-ui-kit/media';
import { Typography } from '@t15-ui-kit/typography';
import { Plus } from '@tochka-modules/t15-ui-kit-icons/24/Stroked/Plus';
import classNames from 'classnames';
import * as styles from 'common/components/ui/single-file-loader/single-file-loader.styles';
import { TCapturedSnapshot } from 'common/utils/features/capture-snapshot';
import { getPlatform } from 'common/utils/features/get-platform';
import { formatBoundaries } from 'common/utils/formatters/format-boundaries';
import { Camera } from 'modules/camera';
import { useCallback, useMemo, useState } from 'react';
import { DropzoneInputProps, DropzoneOptions, DropzoneRootProps, useDropzone } from 'react-dropzone';

export type TSingleFileLoaderUploadCardProps = {
  title: string;
  description?: string;
  accept?: string;
  isCamera?: boolean;
  onUploadRequest(file: File): void;
  onRejectRequest(file: File): void;
};

function SingleFileLoaderUploadCardFromCamera({
  title,
  description,
  onUploadRequest
}: TSingleFileLoaderUploadCardProps): JSX.Element {
  const isMobile = useMobile();
  const [isVideoStream, setVideoStream] = useState(false);

  const handleTake = useCallback(
    (captured: TCapturedSnapshot) => {
      // Формат имени: lime_{platform}_{date}_{boundaries}
      // Формат имени: {YYYY-MM-DDTHH:mm:ss_WxH
      const name = [
        'lime',
        getPlatform(),
        captured.date.toISOString().replace(/\..+$/, ''),
        formatBoundaries(captured.width, captured.height)
      ]
        .filter(Boolean)
        .join('_')
        .concat('.jpeg');

      // Создаем File объект из Blob (чтоб появился name с временной меткой и остальными параметрами)
      const file = new File([captured.blob], name, { type: captured.type });

      debugger;

      onUploadRequest(file);
      setVideoStream(false);
    },
    [onUploadRequest]
  );

  const handleClick = useCallback(() => {
    setVideoStream(true);
  }, []);

  const handleClose = useCallback(() => {
    setVideoStream(false);
  }, []);

  return (
    <div>
      {isVideoStream && <Camera onTake={handleTake} onCancel={handleClose} />}
      <div className={styles.uploadCard(isMobile)} onClick={handleClick}>
        <div>
          <Avatar size="m" border={ColorsShadesEnum.Grey300}>
            <Plus color={ColorsBasicEnum.Brand} />
          </Avatar>
        </div>
        <div className={styles.uploadCardText(isMobile)}>
          <Typography
            type="div"
            size="l"
            weight="500"
            align={isMobile ? 'left' : 'center'}
            color={ColorsBasicEnum.Brand}
          >
            {title}
          </Typography>
          <Typography type="div" size="s" weight={isMobile ? '500' : '400'} color={ColorsBasicEnum.Secondary}>
            {description || 'Не\xA0загружено'}
          </Typography>
        </div>
      </div>
    </div>
  );
}

function SingleFileLoaderUploadCardFromFile({
  title,
  description,
  accept,
  onUploadRequest,
  onRejectRequest
}: TSingleFileLoaderUploadCardProps): JSX.Element {
  const isMobile = useMobile();

  const options = useMemo(
    (): DropzoneOptions => ({
      onDrop: (acceptedFiles: Array<File>, rejectedFiles: Array<File>): void => {
        if (acceptedFiles.length) {
          onUploadRequest(acceptedFiles[0]);
        } else if (rejectedFiles.length) {
          onRejectRequest(rejectedFiles[0]);
        }
      },
      accept,
      multiple: false,
      preventDropOnDocument: true,
      noClick: true,
      noKeyboard: true
    }),
    [accept, onUploadRequest, onRejectRequest]
  );

  const { isDragActive, getRootProps, getInputProps, open } = useDropzone(options);

  const rootProps = useMemo((): DropzoneRootProps => getRootProps(), [getRootProps]);

  const inputProps = useMemo((): DropzoneInputProps => getInputProps({ hidden: true }), [getInputProps]);

  return (
    <div {...rootProps}>
      <div
        className={classNames(styles.uploadCard(isMobile), { [styles.uploadCardActive]: isDragActive })}
        onClick={open}
      >
        <input {...inputProps} />
        <div>
          <Avatar size="m" border={ColorsShadesEnum.Grey300}>
            <Plus color={ColorsBasicEnum.Brand} />
          </Avatar>
        </div>
        <div className={styles.uploadCardText(isMobile)}>
          <Typography
            type="div"
            size="l"
            weight="500"
            align={isMobile ? 'left' : 'center'}
            color={ColorsBasicEnum.Brand}
          >
            {title}
          </Typography>
          <Typography type="div" size="s" weight={isMobile ? '500' : '400'} color={ColorsBasicEnum.Secondary}>
            {description || 'Не\xA0загружено'}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export function SingleFileLoaderUploadCard({ isCamera, ...props }: TSingleFileLoaderUploadCardProps): JSX.Element {
  if (isCamera) {
    return <SingleFileLoaderUploadCardFromCamera {...props} />;
  } else {
    return <SingleFileLoaderUploadCardFromFile {...props} />;
  }
}
