export function prepareRenderingContext(canvas: HTMLCanvasElement): CanvasRenderingContext2D {
  // Зануляем размеры чтоб очистить содержимое
  canvas.width = 0;
  canvas.height = 0;

  const context = canvas.getContext('2d', { alpha: false });

  if (!context) {
    throw new Error('Rendering context of canvas is not available');
  }

  // Очищаем весь контекст
  context.clearRect(0, 0, canvas.width, canvas.height);

  return context;
}
