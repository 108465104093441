// 4k
const defaultWidth = 3840;
const defaultHeight = 2160;

export interface IVideoStream extends MediaStream {
  destroy(): void;
}

export async function createVideoStream(
  width: number = defaultWidth,
  height: number = defaultHeight
): Promise<IVideoStream> {
  if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
    throw new Error('MediaDevices api is not supported');
  }

  const sequencedConstraints: Array<MediaTrackConstraints> = [
    {
      facingMode: { exact: 'environment' },
      width: { ideal: width },
      height: { ideal: height }
    },
    {
      width: { ideal: width },
      height: { ideal: height }
    }
  ];

  let lastError: Error | null = null;

  while (sequencedConstraints.length) {
    const constraints = sequencedConstraints.shift();

    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({ video: constraints });
      const videoStream = mediaStream as IVideoStream;

      Object.defineProperty(videoStream, 'destroy', {
        value(this: MediaStream): void {
          this.getTracks().forEach((track: MediaStreamTrack) => track.stop());
        }
      });

      return videoStream;
    } catch (e) {
      lastError = e as Error;
    }
  }

  if (lastError) {
    throw lastError;
  } else {
    throw new Error('Failed to start media stream');
  }
}
