import { Spinner } from '@t15-ui-kit/circleLoader';
import { ColorsBasicEnum } from '@t15-ui-kit/enums';
import { FlowResult } from '@t15-ui-kit/flowResult';
import { ZindexContext } from '@t15-ui-kit/providers';
import { usePortal } from '@t15-ui-kit/utils';
import { uiKitService } from '@t15-ui-kit/withUiKit';
import { TCapturedSnapshot } from 'common/utils/features/capture-snapshot';
import { createVideoStream, IVideoStream } from 'common/utils/features/video-stream';
import { cx } from 'emotion';
import { ControlsBar } from 'modules/camera/components/controls-bar';
import { TVideoBoxRef, VideoBox } from 'modules/camera/components/video-box';
import { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import * as styles from './camera.styles';

type TCameraProps = {
  onTake(captured: TCapturedSnapshot): void;
  onCancel(): void;
};

const defaultZIndex = 9;

export const Camera = memo(({ onTake, onCancel }: TCameraProps) => {
  const contextualZIndex = useContext(ZindexContext);
  const zIndex = (contextualZIndex ?? defaultZIndex) + 1;

  const portal = usePortal();

  const videoBoxRef = useRef<TVideoBoxRef>(null);
  const mountedRef = useRef(false);

  const [isLoading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [captured, setCaptured] = useState<TCapturedSnapshot | null>(null);

  useEffect(() => {
    createVideoStream()
      .then((stream: IVideoStream) => videoBoxRef.current?.init(stream))
      .catch((error: Error) => {
        if (!mountedRef.current) {
          return;
        }

        setHasError(true);
        console.error(error);

        const handleCloseError = (): void => {
          onCancel();
          uiKitService.closePopup();
        };

        uiKitService.openPopup(
          <FlowResult title="Ошибка" type="Error" onButtonClick={handleCloseError}>
            {[error.name !== 'Error' && error.name, error.message].filter(Boolean).join(': ')}
          </FlowResult>,
          { onCloseRequest: handleCloseError }
        );
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  const handleAccept = useCallback(() => {
    if (!captured) {
      throw new Error('Snapshot is not captured');
    }

    onTake(captured);
  }, [captured, onTake]);

  const handleCapture = useCallback(() => {
    videoBoxRef.current
      ?.capture()
      .then(setCaptured)
      .catch((error: Error) => {
        uiKitService.openPopup(
          <FlowResult title="Ошибка" type="Error" onButtonClick={uiKitService.closePopup}>
            {[error.name !== 'Error' && error.name, error.message].filter(Boolean).join(': ')}
          </FlowResult>,
          { onCloseRequest: uiKitService.closePopup }
        );
      });
  }, []);

  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleReCapture = useCallback(() => {
    videoBoxRef.current?.reset();
    setCaptured(null);
  }, []);

  return createPortal(
    <div className={cx(styles.root, hasError && styles.errored)} style={{ zIndex }}>
      <VideoBox ref={videoBoxRef} />
      {isLoading && (
        <div className={styles.spinner}>
          <Spinner size="3xl" color={ColorsBasicEnum.Default} />
        </div>
      )}
      <ControlsBar
        captured={captured}
        isLoading={isLoading}
        onAccept={handleAccept}
        onCapture={handleCapture}
        onCancel={handleCancel}
        onReCapture={handleReCapture}
      />
    </div>,
    portal
  );
});
