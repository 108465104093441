import { Colors } from '@t15-ui-kit/enums';
import { css } from 'emotion';

export const root = css({
  position: 'relative',
  backgroundColor: Colors.Primary,
  flexGrow: 1,
  width: '100%',
  height: '100%',
  maxHeight: '100%',
  overflow: 'hidden',
  display: 'flex'
});

export const video = css({
  width: '100%',
  height: '100%',
  maxHeight: '100%',
  margin: '0 auto',
  appearance: 'none',
  controls: 'none',
  WebkitAppearance: 'none'
});

export const canvas = css({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  backgroundColor: Colors.Primary,
  opacity: 0,
  zIndex: 1,
  pointerEvents: 'none'
});

export const captured = css({
  opacity: 1,
  pointerEvents: 'auto'
});

export const shutterEffect = css({
  zIndex: 2
});
