import { Colors } from '@t15-ui-kit/enums';
import { css } from 'emotion';

export const root = css({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '65px',
  flexShrink: 0,
  padding: '0 10px',
  position: 'relative',
  backgroundColor: Colors.Primary
});
