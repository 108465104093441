import { Colors } from '@t15-ui-kit/enums';
import { css } from 'emotion';

export const root = css({
  position: 'absolute',
  cursor: 'pointer',
  padding: '0 20px',
  top: 0,
  bottom: 0,
  left: '50%',
  height: '100%',
  display: 'none',
  alignItems: 'center',
  transform: 'translateX(-50%)',
  flexGrow: 1
});

export const visible = css({
  display: 'flex'
});

export const button = css({
  borderRadius: '50%',
  width: '35px',
  height: '35px',
  overflow: 'hidden',
  padding: '4px',
  backgroundColor: Colors.Primary,
  border: `4px solid ${Colors.Default}`,

  ['&:before']: {
    content: '""',
    display: 'block',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    backgroundColor: Colors.Default
  }
});
