export type TCapturedSnapshot = {
  blob: Blob;
  type: string;
  date: Date;
  size: number;
  width: number;
  height: number;
};

export function captureSnapshot(canvas: HTMLCanvasElement): Promise<TCapturedSnapshot> {
  const type = 'image/jpeg';

  // Конвертируем canvas в Blob
  return new Promise((resolve: (snapshot: TCapturedSnapshot) => void, reject: (error: Error) => void) => {
    canvas.toBlob(
      (blob: Blob | null) => {
        if (!blob) {
          reject(new Error('cannot capture blob from canvas'));
        } else {
          resolve({
            blob,
            type,
            date: new Date(),
            size: blob.size,
            width: canvas.width,
            height: canvas.height
          });
        }
      },
      type,
      1
    );
  });
}
