const sizeUnits = ['б', 'кб', 'мб', 'гб', 'тб'];
const bytesInKb = 1024;

export function formatBytes(bytes?: number | null): string | null {
  if (typeof bytes !== 'number') {
    return null;
  }

  if (bytes <= 0) {
    return `0 ${sizeUnits[0]}`;
  }

  const sizeUnitIndex = Math.floor(Math.log(bytes) / Math.log(bytesInKb));
  const humanReadableSize = Math.round(bytes / Math.pow(bytesInKb, sizeUnitIndex));

  return `${humanReadableSize} ${sizeUnits[sizeUnitIndex]}`;
}
