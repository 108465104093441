import { Colors } from '@t15-ui-kit/enums';
import { css } from 'emotion';

export const root = css({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: Colors.Default,
  opacity: 0,
  pointerEvents: 'none'
});
