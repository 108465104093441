export type TVideoBoundaries = {
  width: number;
  height: number;
};

export function getVideoBoundaries(video: HTMLVideoElement): TVideoBoundaries {
  // Реальные размеры контейнера <video /> в пикселях
  const { width: containerWidth, height: containerHeight } = video.getBoundingClientRect();

  // Оригинальное разрешение видео
  const videoAspectRatio = video.videoWidth / video.videoHeight;
  const containerAspectRatio = containerWidth / containerHeight;

  let width: number;
  let height: number;

  // Сравниваем соотношения сторон, чтобы определить масштабирование
  if (containerAspectRatio > videoAspectRatio) {
    // Контейнер шире, чем видео — ограничение по высоте
    height = containerHeight;
    width = containerHeight * videoAspectRatio;
  } else {
    // Контейнер выше, чем видео — ограничение по ширине
    width = containerWidth;
    height = containerWidth / videoAspectRatio;
  }

  return { width, height };
}
