import { Colors } from '@t15-ui-kit/enums';
import { css } from 'emotion';

export const root = css({
  display: 'block',
  textDecoration: 'none',
  fontSize: '16px',
  fontWeight: 500,
  color: Colors.Default,
  opacity: 0
});

export const visible = css({
  opacity: 1
});

export const secondary = css({
  fontWeight: 400,
  color: Colors.Grey400
});

export const description = css({
  fontSize: '14px',
  fontWeight: 400,
  color: Colors.Grey400,
  marginTop: '2px'
});
