import { cx } from 'emotion';
import { memo } from 'react';
import * as styles from './capture-button.styles';

type TCaptureButtonProps = {
  isVisible?: boolean;
  onClick(): void;
};

export const CaptureButton = memo(({ isVisible, onClick }: TCaptureButtonProps) => {
  return (
    <div className={cx(styles.root, isVisible && styles.visible)} role="button" onClick={onClick}>
      <div className={styles.button} />
    </div>
  );
});
