import { cx } from 'emotion';
import { memo, SyntheticEvent, useCallback } from 'react';
import * as styles from './control-link-action.styles';

type TControlLinkActionProps = {
  children: string;
  description?: string;
  isVisible?: boolean;
  isSecondary?: boolean;
  onClick(): void;
};

export const ControlLinkAction = memo(
  ({ children, description, isVisible, isSecondary, onClick }: TControlLinkActionProps) => {
    const handleClick = useCallback(
      (event: SyntheticEvent) => {
        event.preventDefault();
        onClick();
      },
      [onClick]
    );

    return (
      <a
        href="#"
        className={cx(styles.root, isVisible && styles.visible, isSecondary && styles.secondary)}
        onClick={handleClick}
      >
        {children}
        {description && <div className={styles.description}>{description}</div>}
      </a>
    );
  }
);
