import { cx } from 'emotion';
import { forwardRef, memo, Ref, useImperativeHandle, useRef } from 'react';
import * as styles from './shutter-effect.styles';

type TShutterEffectProps = {
  className?: string;
};

export type TShutterEffectRef = {
  run(): void;
};

const animationMs = 100;

export const ShutterEffect = memo(
  forwardRef(({ className }: TShutterEffectProps, ref: Ref<TShutterEffectRef>) => {
    const effectRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(
      ref,
      () => ({
        run(): void {
          const effect = effectRef.current;

          if (!effect) {
            return;
          }

          effect.style.transition = 'opacity 0.1s ease-in';
          effect.style.opacity = '1';

          requestAnimationFrame(() => {
            window.setTimeout(() => {
              effect.style.transition = 'opacity 0.25s ease-out';
              effect.style.opacity = '0';
            }, animationMs);
          });
        }
      }),
      []
    );

    return <div ref={effectRef} className={cx(styles.root, className)} />;
  })
);
