import { prepareRenderingContext } from './rendering-context';
import { getVideoBoundaries } from './video-boundaries';

export function renderSnapshot(video: HTMLVideoElement, canvas: HTMLCanvasElement): void {
  const context = prepareRenderingContext(canvas);

  // Получаем реальные размеры видео
  const width = video.videoWidth;
  const height = video.videoHeight;

  // Устанавливаем размеры canvas с сохранением пропорций
  canvas.width = width;
  canvas.height = height;

  // Рисуем изображение на canvas
  context.drawImage(video, 0, 0, width, height);

  const boundaries = getVideoBoundaries(video);

  // Визуально устанавливаем размеры равные границам самого видео (чтоб оно наложилось ровно)
  canvas.style.width = `${boundaries.width}px`;
  canvas.style.height = `${boundaries.height}px`;
}
