import { SingleFileLoader } from 'common/components/ui/single-file-loader';
import { MEETING_PHOTO_TYPE } from 'common/enums/rpc/lime/meeting-photo-type.enum';
import { ProductDocumentInfoModel } from 'common/models/rpc/lime';
import { HttpRequestError } from 'common/services/http-request';
import { propagateError } from 'common/utils/processing/propagate-error';
import { MeetingSingleComposition } from 'modules/meeting/views/single/compositions/meeting-single.composition';
import { MeetingSingleCompositionContext } from 'modules/meeting/views/single/contexts/meeting-single-composition.context';
import { Component } from 'react';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

type TPhotosOfMeetingActionPhotoProps = {
  document: ProductDocumentInfoModel;
  isCamera: boolean;
};

type TPhotosOfMeetingActionPhotoState = {
  file?: File;
  progress: number;
  isDone: boolean;
  isError: boolean;
  isRemoving: boolean;
};

export class PhotosOfMeetingActionPhoto extends Component<
  TPhotosOfMeetingActionPhotoProps,
  TPhotosOfMeetingActionPhotoState
> {
  readonly #unsubscribe$: Subject<void> = new Subject<void>();

  static contextType = MeetingSingleCompositionContext;
  context!: MeetingSingleComposition;

  state: TPhotosOfMeetingActionPhotoState = {
    progress: 0,
    isDone: false,
    isError: false,
    isRemoving: false
  };

  componentDidMount(): void {
    this.context.photos.store.photos$
      .pipe(
        map((photos: Partial<Record<MEETING_PHOTO_TYPE, string>>): boolean => this.props.document.type in photos),
        distinctUntilChanged(),
        takeUntil(this.#unsubscribe$)
      )
      .subscribe((isDone: boolean): void => {
        if (isDone !== this.state.isDone) {
          this.setState({ isDone });
        }
      });
  }

  componentWillUnmount(): void {
    this.#unsubscribe$.next();
    this.#unsubscribe$.complete();
  }

  render(): JSX.Element {
    const { isCamera } = this.props;
    const { file, progress, isDone, isError, isRemoving } = this.state;

    return (
      <SingleFileLoader
        title={this.props.document.label}
        description={this.props.document.isRequired ? void 0 : 'Необязательно'}
        accept="image/*"
        file={file}
        progress={progress}
        isError={isError}
        isDone={isDone}
        isRemoving={isRemoving}
        isCamera={isCamera}
        onUploadRequest={this.#handleUploadRequest}
        onRejectRequest={this.#handleRejectRequest}
        onCancelRequest={this.#handleCancelRequest}
        onRemoveRequest={this.#handleRemoveRequest}
        onRetryRequest={this.#handleRetryRequest}
      />
    );
  }

  #handleUploadRequest = (file: File): void => {
    propagateError.clear();

    this.setState({
      file,
      progress: 0,
      isDone: false,
      isError: false,
      isRemoving: false
    });

    this.context.photos
      .uploadPhoto(file, this.props.document.type, (progress: number): void => this.setState({ progress }))
      .catch((error: Error): void => {
        if (error instanceof HttpRequestError && error.isAbort) {
          return;
        }

        propagateError(error);
        this.setState({ isError: true });
      });
  };

  #handleRejectRequest = (file: File): void => {
    propagateError(new Error(`Файл ${file.type} не может быть загружен`));
  };

  #handleCancelRequest = (): void => {
    this.setState({
      file: void 0,
      progress: 0,
      isDone: false,
      isError: false,
      isRemoving: false
    });

    this.context.photos.cancelUploadPhoto(this.props.document.type);
  };

  #handleRemoveRequest = (): void => {
    propagateError.clear();
    this.setState({ isRemoving: true });

    this.context.photos
      .removePhoto(this.props.document.type)
      .then((): void => {
        this.setState({
          file: void 0,
          progress: 0,
          isDone: false,
          isError: false,
          isRemoving: false
        });
      })
      .catch((error: Error): void => {
        propagateError(error);

        this.setState({ isRemoving: false });
      });
  };

  #handleRetryRequest = (): void => {
    if (this.state.file) {
      this.#handleUploadRequest(this.state.file);
    }
  };
}
