import { Colors } from '@t15-ui-kit/enums';
import { css } from 'emotion';

export const root = css({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: Colors.Primary,
  color: Colors.Default,
  display: 'flex',
  flexFlow: 'column nowrap',
  overflow: 'hidden'
});

export const spinner = css({
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: Colors.Primary
});

export const errored = css({
  display: 'none'
});
