import { TCapturedSnapshot } from 'common/utils/features/capture-snapshot';
import { formatBoundaries } from 'common/utils/formatters/format-boundaries';
import { formatBytes } from 'common/utils/formatters/format-bytes';
import { memo } from 'react';
import { CaptureButton } from './../capture-button';
import { ControlLinkAction } from './../control-link-action';
import * as styles from './controls-bar.styles';

type TControlsBarProps = {
  captured?: TCapturedSnapshot | null;
  isLoading?: boolean;
  onAccept(): void;
  onCapture(): void;
  onCancel(): void;
  onReCapture(): void;
};

export const ControlsBar = memo(
  ({ captured, isLoading, onAccept, onCapture, onCancel, onReCapture }: TControlsBarProps) => {
    return (
      <div className={styles.root}>
        <ControlLinkAction
          description={[formatBoundaries(captured?.width, captured?.height), formatBytes(captured?.size)]
            .filter(Boolean)
            .join(', ')}
          isVisible={!!captured && !isLoading}
          onClick={onAccept}
        >
          Использовать фото
        </ControlLinkAction>
        <CaptureButton isVisible={!captured && !isLoading} onClick={onCapture} />
        <ControlLinkAction onClick={captured ? onReCapture : onCancel} isVisible isSecondary>
          {captured ? 'Переснять' : 'Отмена'}
        </ControlLinkAction>
      </div>
    );
  }
);
