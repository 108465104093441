import { CellEmpty } from '@t15-ui-kit/cellEmpty';
import { CircleLoader } from '@t15-ui-kit/circleLoader';
import { ColorsBasicEnum } from '@t15-ui-kit/enums';
import { useMobile } from '@t15-ui-kit/media';
import { AllColors } from '@t15-ui-kit/types';
import { Typography } from '@t15-ui-kit/typography';
import { Trash } from '@tochka-modules/t15-ui-kit-icons/24/Stroked/Trash';
import { SingleFileLoaderFileCardContent } from 'common/components/ui/single-file-loader/single-file-loader-file-card-content';
import * as styles from 'common/components/ui/single-file-loader/single-file-loader.styles';
import { usePreview } from 'common/components/ui/single-file-loader/single-file-loader.utils';

export type TSingleFileLoaderFileCardProps = {
  title: string;
  file?: Blob;
  progress?: number;
  isError?: boolean;
  isBroken?: boolean;
  isDone?: boolean;
  isRemoving?: boolean;
  onCancelRequest?(): void;
  onRemoveRequest?(): void;
  onRetryRequest?(): void;
};

export function SingleFileLoaderFileCard({
  title,
  file,
  progress,
  isError,
  isBroken,
  isDone,
  isRemoving,
  onCancelRequest,
  onRemoveRequest,
  onRetryRequest
}: TSingleFileLoaderFileCardProps): JSX.Element {
  const isMobile = useMobile();

  const isUploading = !isError && !isBroken && !isDone;

  const preview = usePreview(file);

  return (
    <div className={styles.fileCard(isMobile)}>
      <div className={styles.fileCardContent(isMobile)}>
        <SingleFileLoaderFileCardContent
          isUploading={isUploading}
          isError={isError}
          progress={progress}
          preview={preview}
          onCancelRequest={onCancelRequest}
          onRetryRequest={onRetryRequest}
        />
      </div>
      <div className={styles.fileCardFooter(isMobile)}>
        <div className={styles.fileCardFooterContent}>
          <Typography
            type="div"
            size={isMobile ? 'l' : 'm'}
            weight="500"
            color={isError ? ColorsBasicEnum.Alert : ColorsBasicEnum.Primary}
            ellipsis
          >
            {title}
          </Typography>
          <CellEmpty height={isMobile ? '5' : '2'} />
          <Typography type="div" size="s" weight={isMobile ? '500' : '400'} color={ColorsBasicEnum.Secondary}>
            {isDone ? 'Загружено' : isError ? 'Ошибка загрузки' : 'Загружается'}
          </Typography>
        </div>

        {isRemoving ? (
          <div className={styles.fileCardFooterLoader}>
            <CircleLoader size={isMobile ? 's' : 'm'} />
          </div>
        ) : (
          (!!isDone || !!isError) && (
            <button className={styles.fileCardFooterButton} onClick={onRemoveRequest} type="button">
              <Trash size={isMobile ? 'm' : 'l'} color={'currentColor' as AllColors} />
            </button>
          )
        )}
      </div>
    </div>
  );
}
